const LOCALE_SE = "sv-SE";
const LOCALE_SE_SW = "sv-SE-sw";
const LOCALE_DK = "da-DK";
const LOCALE_DK_SW = "da-DK-sw";
const LOCALE_FI = "fi-FI";
const LOCALE_FI_SW = "fi-FI-sw";
const LOCALE_NO = "nb-NO";
const LOCALE_NO_SW = "nb-NO-sw";

const LocaleService = {
	getLocale() {
		let locale = localStorage.getItem("locale");

		if (!locale) {
			let suffix = "";

			const index = location.hostname.lastIndexOf(".");

			if (index > 0) {
				suffix = location.hostname.substr(index).toLocaleLowerCase();
			}
			const isSmartWash = location.hostname.indexOf("smartwash") >= 0;
			console.debug("THis is smartwash", isSmartWash);

			switch (suffix) {
				case ".se":
					locale = isSmartWash ? LOCALE_SE_SW : LOCALE_SE;
					break;
				case ".dk":
					locale = isSmartWash ? LOCALE_DK_SW : LOCALE_DK;
					break;
				case ".fi":
					locale = isSmartWash ? LOCALE_FI_SW : LOCALE_FI;
					break;
				default:
					locale = isSmartWash ? LOCALE_NO_SW : LOCALE_NO;
			}
		}

		return locale;
	},

	setLocale(locale) {
		localStorage.setItem("locale", locale);
	},
};

export default LocaleService;
